import React from "react";
import Header from "./components/MainOutlay/Header";
import Body from "./components/MainOutlay/Body";
import Footer from "./components/MainOutlay/Footer";
import { Layout } from "antd";
import ComingSoon from "./components/ComingSoon";
const { Content } = Layout;

const App = () => {
  return (
    // <Layout>
    //   <div style={{ height: "10vh" }}>
    //     <Header />
    //   </div>
    //   <div style={{ height: "80vh" }}>
    //     <Body />
    //   </div>
    //   <div style={{ height: "10vh" }}>
    //     <Footer />
    //   </div>
    // </Layout>

    <ComingSoon />
  );
};

export default App;
