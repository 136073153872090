import React from "react";
import "./ComingSoon.scss"; // Import your CSS file

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <div className="image-container">
        <img
          src="https://avhqipebz61dalak.public.blob.vercel-storage.com/Website%20Coming%20Soon%20Image-RjgyQPZp7DrDId3CFblHwy3AcZ2qpX.jpg"
          alt="Coming Soon"
          className="coming-soon-image"
        />
      </div>
    </div>
  );
};

export default ComingSoon;
